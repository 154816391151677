import { KeyboardArrowUp } from '@mui/icons-material';
import { AppBar, Box, Container, Fab, Fade, IconButton, Slide, Stack, styled, Toolbar, Typography, useMediaQuery, useScrollTrigger, useTheme } from "@mui/material";
import Nav from "./Nav";

interface Props {
    children: React.ReactElement;
}

function ScrollTop(props: Props) {
    const { children } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });


    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = (
            (event.target as HTMLDivElement).ownerDocument || document
        ).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
            });
        }

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 100000 }}
            >
                {children}
            </Box>
        </Fade>
    );
}

const TitleSlogan = () => {

    const theme = useTheme();
    const idGreaterThanMd = useMediaQuery(theme.breakpoints.up("md"));
    const idGreaterThanLd = useMediaQuery(theme.breakpoints.up("lg"));
    const title = "Comfort & Jane Solicitors";
    const slogan = "Legal Practitioners and Arbitrators";

    return (
        <Stack
            direction={idGreaterThanLd ? "row" : "column"}
            alignItems="center"
            spacing={idGreaterThanLd ? 8 : 4}
            sx={{
                width: "fit-content",
                marginRight: "auto !important",
                marginLeft: "4rem !important"
            }}
        >
            <Typography variant="body1" color="black" ><b>{title}</b></Typography>
            <Typography variant="body2" color="black" >{slogan}</Typography>
        </Stack>
    );
}

const Header = () => {

    const appBarHeight = '7.5rem';

    const theme = useTheme();
    const trigger = useScrollTrigger();
    const isAwayFromTop = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });

    const logoHeight = '7.5rem';
    const LogoImg = styled('img')(() => ({
        height: '100%',
        margin: 'auto'
    }));

    return (
        <>
            <Slide appear={false} direction='down' in={!trigger}>
                <AppBar
                    sx={{
                        backgroundColor: isAwayFromTop ? 'rgba(255, 255, 255, 1) !important' : 'transparent !important',
                        boxShadow: isAwayFromTop ? '0px 6px 18px -8px rgb(58 53 65 / 56%) !important' : 'none !important',
                        px: { xs: 0, md: 4 }
                    }}
                >
                    <Container maxWidth={false}>
                        <Toolbar disableGutters>
                            <Box sx={{ display: { xs: 'none', md: 'flex' }, height: logoHeight }} >
                                <IconButton
                                    onClick={() => {
                                        window.open("/", "_self");
                                    }}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "transparent !important"
                                        }
                                    }}
                                >
                                    <LogoImg src="/images/logo.png" />
                                </IconButton>
                            </Box>
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, height: logoHeight }} >
                                <LogoImg
                                    onClick={() => {
                                        window.open("/", "_self");
                                    }}
                                    style={{ marginLeft: 0 }} src="/images/logo.png"
                                />
                            </Box>
                            <Container sx={{
                                display: { xs: "none !important", md: "flex !important", width: "fit-content" }
                            }}  >
                                <TitleSlogan />
                            </Container>
                            <Nav />
                        </Toolbar>
                    </Container>
                </AppBar>
            </Slide>
            <Box sx={{ height: appBarHeight, marginTop: "0 !important" }} ></Box>
            <ScrollTop>
                <Fab sx={{ backgroundColor: theme.palette.primary.main, '&:hover': { backgroundColor: theme.palette.primary.main } }} size="small" aria-label="scroll back to top">
                    <KeyboardArrowUp sx={{ color: 'white' }} />
                </Fab>
            </ScrollTop>
        </>
    );
}

export default Header;