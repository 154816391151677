import { lazy } from "react";
import Loadable from "../components/Loadable";
import GeneralLayout from "../layouts/GeneralLayout/GeneralLayout";

const LLandingPage = Loadable(lazy(() => import('../pages/LandingPage/LandingPage')));
const LAboutPage = Loadable(lazy(() => import('../pages/About/About')));



const NoAuthRoutes = {
    path: "/",
    element: <GeneralLayout />,
    children: [
        {
            path: "welcome",
            element: <LLandingPage />
        },
        {
            path: "about",
            element: <LAboutPage />
        },
    ]
}

export default NoAuthRoutes;