// material-ui
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from '@mui/material';

// loader style
const LoaderWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 0,
  width: '100%',
  '& > * + *': {
    marginTop: theme.spacing(2)
  }
}));

// ==============================|| Loader ||============================== //

const Loader = ({ showProgress = true }) => (
  <LoaderWrapper

  >
    <LinearProgress
      color="primary"
      sx={{
        opacity: showProgress ? "100%" : "0% !important"
      }}
    />
    <Box
      sx={{
        zIndex: "0",
        position: "fixed",
        left: "0",
        top: "0",
        width: "100%",
        height: "100%",
        display: "flex",
        "& > *": {
          m: "auto !important"
        }
      }}
    >
      <img src="/images/preloader.gif" />
    </Box>
  </LoaderWrapper>
);

export default Loader;
