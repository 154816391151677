import { Box, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader";
import Header from "./components/Header";

const GeneralLayout = () => {
    return (
        <Stack
            alignItems="center"
            width={"100%"}
            spacing={4}
            sx={{
                display: 'flex',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                position: 'relative',
                overflow: 'hidden',
                minHeight: '100vh',
                p: 4,
                pt: 0,
                pb: 0
            }}
        >
            <Header />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: {
                        xs: 2,
                        sm: 3
                    },
                    position: "relative",
                    pb: "0 !important",
                    display: 'flex',
                    marginLeft: "-1rem",
                    width: 'calc(100% + 2rem)',
                    minHeight: {
                        xs: "80vh",
                        md: "100vh"
                    },
                    marginTop: '0 !important',
                    overflow: "hidden"
                }}
            >
                <Outlet />
            </Box>
            <Footer />
        </Stack>
    );
}

export default GeneralLayout;