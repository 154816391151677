import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IRoutesState {
    presentRoute: string;
}

const initialState = {
    presentRoute: ""
};

const routes = createSlice({
    name: "routes",
    initialState,
    reducers: {
        resetPresentRoute: (state: IRoutesState) => {
            state.presentRoute  = "";
        }, 
        setPresentRoute: (state: IRoutesState, action: PayloadAction<string>) =>  {
            state.presentRoute = action.payload
        }
    }
});

export const {
    resetPresentRoute,
    setPresentRoute
} = routes.actions;

export default routes.reducer;