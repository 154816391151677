export default {
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: 'none',
        color: "rgb(89, 89, 89)"
      }
    }
  }
}
