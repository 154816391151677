const navItems = [
    {
        id: 'home',
        title: 'Home',
        type: 'item',
        url: '/welcome',
        userAuth: false     //user-authentication is not a pre-requisite for visiting this  page
    },
    {
        id: 'practiceAreas',
        title: 'Practice Areas',
        type: 'item',
        url: '/practice-areas',
        userAuth: false
    },
    {
        id: 'about',
        title: 'About',
        type: 'item',
        url: '/about',
        userAuth: false
    },
    {
        id: 'contact',
        title: 'Contact',
        type: 'item',
        url: '/contact',
        userAuth: false
    }
];

export default navItems;