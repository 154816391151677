import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { SettingsProvider, SettingsConsumer } from "./@core/context/settingsContext";
import ThemeComponent from "./@core/theme/ThemeComponent";
import ScrollTop from "./components/ScrollTop";
import AppRoutes from "./routes/AppRoutes";
import ThemeCustomization from "./themes/themes";

function App() {
  return (
    <SettingsProvider>
      <SettingsConsumer>
        {({ settings }) => {
          return (
            <ThemeComponent settings={settings}>
              {/* {getLayout(<Component {...pageProps} />)} */}
              <ScrollTop>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <AppRoutes />
                </LocalizationProvider>
              </ScrollTop>
            </ThemeComponent>
          )
        }}
      </SettingsConsumer>
    </SettingsProvider>
  );
}

export default App;
