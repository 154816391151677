import { lazy } from "react";
import Loadable from "../components/Loadable";
import GeneralLayout from "../layouts/GeneralLayout/GeneralLayout";

const LNotFound = Loadable(lazy(() => import("../pages/NotFound/NotFound")));

const NotFoundRoute = {
    path: "/",
    element: <GeneralLayout />,
    children: [
        {
            path: "*",
            element: <LNotFound />
        }
    ]
}

export default NotFoundRoute;