import { Box, Button, Drawer, IconButton, List, ListItem, ListItemButton, Stack, Typography, useTheme } from "@mui/material";
import { FC, Fragment, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import navItems from "./navItems";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GavelIcon from '@mui/icons-material/Gavel';
import InfoIcon from '@mui/icons-material/Info';
import PhoneLockedIcon from '@mui/icons-material/PhoneLocked';
import { useDispatch, useSelector } from "react-redux";
import { IRoutesState, setPresentRoute } from "../../../stateManagement/reducers/routesReducer";
import { IRootReducerT } from "../../../stateManagement/store";
import { Close } from "@mui/icons-material";

function getNavIcon(navTitle: string) {
    switch (navTitle) {
        case 'Home':
            return <AccountBalanceIcon />;
        case 'Practice Areas':
            return <GavelIcon />;
        case 'About':
            return <InfoIcon />
        case 'Contact':
            return <PhoneLockedIcon />
        default:
            return null;
    }
}

interface IMobileNavProps {
    setPresentPage: (page: string) => void;
}

const MobileNavigation: FC<IMobileNavProps> = ({ setPresentPage }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();

    const k = 1;
    const drawerWidth = 240;

    const [mobileOpen, setMobileOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    // const isAwayFromTop = useScrollTrigger({
    //     disableHysteresis: true,
    //     threshold: 0
    // });

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <List
                sx={{
                    height: "100%"
                }}
            >
                {navItems.map((page, index) => (
                    <ListItem key={`${index}${k}`} disablePadding>
                        <ListItemButton
                            sx={{ textAlign: 'center', py: 4 }}
                            onClick={() => {
                                setPresentPage(page.url);
                                dispatch(setPresentRoute(page.url));
                                if ((page.id === "practiceAreas")) {
                                    if ((location.pathname !== "/welcome")) {
                                        navigate("/", { replace: true });
                                    }
                                } else if (page.id !== "contact") {
                                    navigate(page.url, { replace: true });
                                }
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={4}
                                alignItems="center"
                                justifyContent="flex-start"
                                sx={{
                                    width: "fit-content !important",
                                    color: (location.pathname === page.url) ? '#3f50b5' : 'black', '&:hover': { color: `#3f50b5 !important` }
                                }}
                            >
                                {getNavIcon(page.title)}
                                <Typography variant="body1" sx={{
                                    color: (location.pathname === page.url) ? '#3f50b5' : 'black', '&:hover': { color: `#3f50b5 !important` }
                                }} >

                                    {
                                        (page.id === "contact")

                                        &&

                                        <a target="_blank" style={{ color: "black", textDecoration: "none" }} href="mailto:jolaade@comfortjane.com?subject=Request for Legal Representation" >{page.title}</a>
                                    }
                                    {
                                        (page.id !== "contact")

                                        &&

                                        page.title
                                    }
                                </Typography>
                            </Stack>
                        </ListItemButton>
                    </ListItem>

                ))}
            </List>
        </Box>
    );

    return (
        <>
            <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 4 }}>
                <IconButton
                    size="medium"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    color="inherit"
                    sx={{
                        color: 'black',
                        bgcolor: 'transparent',
                        borderRadius: '.5rem .5rem'
                    }}
                >
                    <DragHandleIcon />
                </IconButton>
            </Box>
            <nav>
                <Drawer
                    // container={container}
                    anchor="right"
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    BackdropProps={{
                        sx: {
                            backgroundColor: "rgba(0, 0, 0, 0.5)!important"
                        }
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        height: 'fit-content'
                    }}
                    PaperProps={{ sx: { backgroundColor: 'rgba(255, 255, 255, 1)', height: '100% !important', pt: 10, pb: 20, pl: 5 } }}
                >
                    <Stack
                        alignItems="flex-end"
                        sx={{
                            width: "100%",
                            p: "1rem",
                            pt: "0",
                            pb: "2rem",
                            position: "absolute",
                            left: 0,
                            top: 0
                        }}
                    >
                        <IconButton onClick={handleDrawerToggle} sx={{ m: ".5rem" }} >
                            <Close sx={{ color: theme.palette.error.main }} />
                        </IconButton>
                    </Stack>
                    {drawer}
                    <Typography
                        sx={{
                            width: "100%",
                            position: "absolute",
                            left: "0",
                            bottom: "0",
                            p: "1rem",
                            fontSize: ".7rem"
                        }}
                        textAlign="center"
                    >
                        <b style={{ color: theme.palette.primary.main }} >COMFORT & JANE SOLICITORS</b> | ALL RIGHTS RESERVED! &copy; 2024
                    </Typography>
                </Drawer>
            </nav>
        </>
    );
}


const Nav = () => {

    const dispatch = useDispatch();

    const { presentRoute } = useSelector<IRootReducerT, IRoutesState>(state => state.routes);
    const location = useLocation();
    const [presentPage, setPresentPage] = useState("");
    const navigate = useNavigate();

    const [isInitRender, setIsInitRender] = useState(true);
    useEffect(() => {

        if (isInitRender) {
            setPresentPage(location.pathname);
            setIsInitRender(false);
            return;
        }

        if (presentPage !== "") {
            setPresentPage(presentRoute);
        }
    }, [presentPage]);

    return (
        <>
            <Box
                id="demo-customized-button"
                sx={{ marginLeft: 'auto', marginRight: 0, display: { xs: 'none', md: 'flex' } }}
            >
                {
                    navItems.map((page, index) => {
                        return (
                            <Fragment key={index}>
                                <Button
                                    sx={{ my: 2, color: 'white', display: 'block', '&:hover': { backgroundColor: 'transparent', color: 'primary' } }}
                                    onClick={() => {
                                        setPresentPage(page.url);
                                        dispatch(setPresentRoute(page.url));
                                        if ((page.id === "practiceAreas")) {
                                            if ((location.pathname !== "/welcome")) {
                                                navigate("/", { replace: true });
                                            }
                                        } else if (page.id !== "contact") {
                                            navigate(page.url, { replace: true });
                                        }
                                    }}
                                >
                                    <Typography variant="body1" sx={{ color: (location.pathname === page.url) ? '#3f50b5' : 'black', '&:hover': { color: '#3f50b5 !important' } }} >
                                        {
                                            (page.id === "contact")

                                            &&

                                            <a target="_blank" style={{ color: "black", textDecoration: "none" }} href="mailto:jolaade@comfortjane.com?subject=Request for Legal Representation" >{page.title}</a>
                                        }
                                        {
                                            (page.id !== "contact")

                                            &&

                                            page.title
                                        }
                                    </Typography>
                                </Button>
                            </Fragment>
                        );
                    })
                }
            </Box>
            <MobileNavigation setPresentPage={setPresentPage} />
        </>
    );
}

export default Nav;