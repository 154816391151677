import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {

    const theme = useTheme();
    const idGreaterThanMd = useMediaQuery(theme.breakpoints.up("md"));

    const title = "Comfort & Jane Solicitors";
    const slogan = "Legal Practitioners and Arbitrators";

    const officeAddress = "Suite 7, Law Hub, Ring Road, Ibadan";

    const line1 = "+234 818 856 6937";
    const line2 = "+234 812 999 1303";

    const socialMediaData = [
        {
            url: "https://x.com/ajollyblom",
            platform: "X",
            icon: <XIcon sx={{ height: "1.875rem", width: "1.875rem" }} />
        },
        {
            url: "https://www.facebook.com/jolaade.falade/",
            platform: "Facebook",
            icon: <FacebookIcon sx={{ height: "1.875rem", width: "1.875rem" }} />
        },
        {
            url: "https://www.instagram.com/ajollyblom/?hl=en",
            platform: "Instagram",
            icon: <InstagramIcon sx={{ height: "1.875rem", width: "1.875rem" }} />
        },
    ]

    return (
        <Stack
            alignItems="center"
            sx={{
                width: "100%",
                marginTop: "0 !important",
                py: {
                    xs: "2rem !important",
                    md: "4rem !important"
                },
                pb: "2rem"
            }}
        >
            <Grid
                container
                sx={{
                    width: "100%",
                    maxWidth: "1000px !important"
                }}
            >
                <Grid item xs={12} md={6}>
                    <Stack
                        alignItems={idGreaterThanMd ? "flex-start" : "center"}
                        spacing={8}
                        sx={{

                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: { xs: "center", md: "left" },
                                color: "black",
                                cursor: "pointer",
                                fontWeight: "700",
                                fontSize: { xs: "1.25rem", md: "1.5rem" },
                                lineHeight: { xs: "1.25rem", md: "1.5rem" },
                                borderBottom: "solid .1853rem transparent",
                                "&:hover": {
                                    borderBottom: "solid .1853rem black"
                                }
                            }}
                            onClick={() => {
                                window.open("/", "_self");
                            }}

                        >{title.toUpperCase()}</Typography>
                        <Typography
                            sx={{
                                textAlign: { xs: "center", md: "left" },
                                color: "black",
                                fontWeight: "400",
                                fontSize: "1rem",
                                lineHeight: "1rem",
                            }}

                        >{slogan}</Typography>
                        <Typography
                            sx={{
                                textAlign: { xs: "center", md: "left" },
                                color: "black",
                                fontWeight: "400",
                                fontSize: { xs: "1.025rem", md: "1.125rem" },
                                lineHeight: { xs: "1.025rem", md: "1.125rem" },
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                // Encode the address to make it URL-safe
                                const encodedAddress = encodeURIComponent(officeAddress);

                                // Create the Google Maps URL with the encoded address
                                const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;

                                // Open the Google Maps URL in a new tab
                                window.open(googleMapsUrl, '_blank');
                            }}
                        ><b>{officeAddress}</b></Typography>
                        <Typography
                            sx={{
                                textAlign: { xs: "center", md: "left" },
                                color: "black",
                                fontWeight: "400",
                                fontSize: { xs: "1.025rem", md: "1.125rem" },
                                lineHeight: { xs: "1.025rem", md: "1.125rem" },
                            }}

                        ><b><a style={{ textDecoration: "none", color: "inherit" }} href={`tel:${line1}`} >{line1}</a>, <a style={{ textDecoration: "none", color: "inherit" }} href={`tel:${line2}`} >{line2}</a></b></Typography>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={8}
                        >
                            {
                                socialMediaData.map((data, index) => {
                                    return (
                                        <Box
                                            key={index}
                                            onClick={() => {
                                                window.open(data.url, "_blank")
                                            }}
                                            sx={{
                                                color: "black",
                                                cursor: "pointer",
                                                transition: "all .25s linear 0s",
                                                "&:hover": {
                                                    transform: "scale(110%, 110%)"
                                                }
                                            }}
                                        >{data.icon}</Box>
                                    );
                                })
                            }
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>

                </Grid>
            </Grid>
            <Typography
                color="black"
                textAlign="center"
                variant="h3"
                sx={{
                    margin: '0 !important',
                    fontSize: "1.125rem !important",
                    fontWeight: 400,
                    pt: {
                        xs: "4rem !important",
                        md: '8rem !important'
                    }
                }}
            >
                <b
                    onClick={() => window.open("/", "_self")}
                    style={{
                        color: theme.palette.primary.main,
                        cursor: "pointer",
                        borderBottom: "Solid .0625ren inherit"
                    }}
                >COMFORT & JANE SOLICITORS</b> | ALL RIGHTS RESERVED! &copy; 2024
            </Typography>
        </Stack>
    );
}

export default Footer;