import { configureStore } from "@reduxjs/toolkit";
import routesReducer, { IRoutesState } from "./reducers/routesReducer";


export interface IRootReducerT {
    routes: IRoutesState
}


const store = configureStore({
    reducer: {
        routes: routesReducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    }),
});

export default store;