import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RedirectPage = () => {

    const navigate = useNavigate();

    useEffect(() => {
        navigate('/welcome', { replace: true });
    }, []);

    return null;
}

const LandingRoutes = {
    path: '/',
    element: <RedirectPage />
}

export default LandingRoutes;